import React from "react";
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FormGroup, FormControlLabel, Checkbox, Typography, Switch, RadioGroup, Radio } from '@material-ui/core';


const styles = theme => ({
    formControl: {
        backgroundColor: '#D7F0CA',
        padding: '10px 20px',
        position: 'relative'
    },
    typography: {
        display: 'flex',
        alignItems: 'center',
        marginRight: '15px',
        fontSize: '20px'
    },
    switch: {
        right: '20px',
        position: 'absolute'
    },
    radioLabel: {
        margin: 0
    },
    radio: {
        paddingTop: 0
    }
});
class TreeFormControl extends React.Component {

    render() {
        const { classes, changeFormControl, checkedBoxes, lang } = this.props;
        return (
            <div>
                <FormGroup row className={classes.formControl}>
                    <Typography className={classes.typography}>Select desired:</Typography>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedBoxes.manuals}
                                disabled={checkedBoxes.grouping}
                                onChange={() => changeFormControl('manuals')}
                                value="manuals"
                                color="primary"
                            />
                        }
                        label="Manuals"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedBoxes.cover}
                                disabled={checkedBoxes.grouping}
                                onChange={() => changeFormControl('cover')}
                                value="cover"
                                color="primary"
                            />
                        }
                        label="CoverPage"
                    />
                    {lang === 'en' && !checkedBoxes.grouping && checkedBoxes.cover &&
                        <RadioGroup
                            aria-label="position"
                            name="position"
                            value={checkedBoxes.coverGroup}
                            onChange={(e) => changeFormControl('coverGroup', e)}
                            row
                        >
                            <FormControlLabel
                                className={classes.radioLabel}
                                value="ng"
                                control={<Radio className={classes.radio} color="primary" />}
                                label="NG"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                className={classes.radioLabel}
                                value="en"
                                control={<Radio className={classes.radio} color="primary" />}
                                label="EN"
                                labelPlacement="top"
                            />
                        </RadioGroup>
                    }
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedBoxes.introduction}
                                disabled={checkedBoxes.grouping}
                                onChange={() => changeFormControl('introduction')}
                                value="introduction"
                                color="primary"
                            />
                        }
                        label="Introduction"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedBoxes.description}
                                disabled={checkedBoxes.grouping}
                                onChange={() => changeFormControl('description')}
                                value="description"
                                color="primary"
                            />
                        }
                        label="Description"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedBoxes.benefits}
                                disabled={checkedBoxes.grouping}
                                onChange={() => changeFormControl('benefits')}
                                value="benefits"
                                color="primary"
                            />
                        }
                        label="Benefit"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checkedBoxes.capabilities}
                                disabled={checkedBoxes.grouping}
                                onChange={() => changeFormControl('capabilities')}
                                value="capabilities"
                                color="primary"
                            />
                        }
                        label="Capabilities"
                    />
                    <FormControlLabel
                        className={classes.switch}
                        control={
                            <Switch
                                checked={checkedBoxes.grouping}
                                onChange={() => changeFormControl('grouping')}
                                value="checkedB"
                            />
                        }
                        label={<Typography className={classes.typography}>Grouping</Typography>}
                    />
                </FormGroup>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}
function mapDispatchToProps(dispatch) {
    return {
    };
}
export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(TreeFormControl));