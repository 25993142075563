import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { Button, Divider } from '@material-ui/core';


import Tree, { TreeNode } from 'rc-tree';
import 'rc-tree/assets/index.css';

class MTree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gData: [],
            checked: [],
            autoExpandParent: true,
            expanded: [],
            lang: 'en'
        }
    }
    componentDidMount() {
        this.setState({ gData: this.props.nodes });
        this.setState({ lang: this.props.lang });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.gData !== this.props.nodes) {
            this.setState({
                gData: this.props.nodes
            });
        }
        // if (prevState.lang !== this.props.lang) {
        //     this.setState({
        //         lang: this.props.lang
        //     });
        //     this.setState({ checked: [] });
        //     this.props.setChecked([]);
        // }
    }

    onCheck = (checkedKeys, info) => {
        let checked = checkedKeys.filter(item => item.includes('/'));
        this.setState({ checked });
        this.props.setChecked(checked);
    }

    onExpand = (expandedKeys) => {
        this.setState({
            expanded: expandedKeys
        });
    }

    onDragEnter = (info) => {
        this.setState({
            expanded: info.expandedKeys,
        });
    }

    onDrop = (info) => {
        // console.log('drop', info);
        const dropKey = info.node.props.eventKey;
        const dragKey = info.dragNode.props.eventKey;
        const dropPos = info.node.props.pos.split('-');
        const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);
        const loop = (data, key, callback) => {
            data.forEach((item, index, arr) => {
                if (item.key === key) {
                    callback(item, index, arr);
                    return;
                }
                if (item.children) {
                    loop(item.children, key, callback);
                }
            });
        };
        const data = [...this.state.gData];
        let dragObj;
        loop(data, dragKey, (item, index, arr) => {
            arr.splice(index, 1);
            dragObj = item;
        });
        if (info.dropToGap) {
            let ar;
            let i;
            loop(data, dropKey, (item, index, arr) => {
                ar = arr;
                i = index;
            });
            if (dropPosition === -1) {
                ar.splice(i, 0, dragObj);
            } else {
                ar.splice(i + 1, 0, dragObj);
            }
        } else {
            loop(data, dropKey, (item) => {
                item.children = item.children || [];
                // where to insert
                item.children.push(dragObj);
            });
        }
        this.setState({
            gData: data,
        });
    }

    render() {
        const loop = data => {
            return data.map((item) => {
                if (item.children && item.children.length) {
                    return <TreeNode key={item.key} title={item.title}>{loop(item.children)}</TreeNode>;
                }
                return <TreeNode key={item.key} title={item.title} />;
            });
        };

        return (
            <div>
                <Divider />
                <Tree
                    showLine
                    checkable
                    draggable
                    // checkedKeys={this.state.checked}
                    defaultCheckedKeys={this.state.checked}
                    defaultExpandedKeys={this.state.expanded}
                    selectable={false}
                    onCheck={this.onCheck}
                    onDragEnter={this.onDragEnter}
                    onDrop={this.onDrop}
                >
                    {loop(this.state.gData)}
                </Tree>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
    };
}
function mapDispatchToProps(dispatch) {
    return {
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MTree);