import React from 'react';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
import { prepare } from '../utils/prepareXLSX';
import i18n from '../i18n/i18n';
import {
    Button, DialogActions, TextField,
    Dialog, DialogContent, DialogTitle
} from '@material-ui/core';
import { exportXLSX } from '../actions/ExportActions';

class ExportXLSX extends React.Component {
    constructor(props) {
        super(props);
    }

    onOk = (data) => {
        this.props.exportXLSX(data, this.props.template);
        this.props.handleClose();
    }

    render() {
        const { paths, open } = this.props;
        // const { onOk } = this;
        return (
            <StaticQuery
                query={graphql`
                    query ExportXSLXQuery {
                        allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___module, frontmatter___priority]}) {
                        totalCount
                          edges {
                            node {
                              frontmatter {
                                title
                                path
                                lang
                                priority
                                formPriority
                                type
                                module
                              }
                              rawMarkdownBody
                              id
                            }
                          }
                        }
                    }`
                }
                render={data => {
                    let formatedXLSX = prepare(data, paths);
                    return (
                        <Dialog
                            open={open}
                            onClose={this.props.handleClose}
                            fullWidth={true}
                            disableBackdropClick
                            maxWidth="sm"
                            aria-labelledby="responsive-dialog-title"
                        >
                            <DialogTitle id="responsive-dialog-title">{i18n.t(this.props.dialogTitle)}</DialogTitle>
                            <DialogActions>
                                <Button onClick={() => this.onOk(formatedXLSX)} style={{ backgroundColor: '#61a50e', color: 'white' }}>
                                    {i18n.t('Ok')}
                                </Button>
                                <Button onClick={this.props.handleClose} style={{ backgroundColor: '#61a50e', color: 'white' }}>
                                    {i18n.t('Cancel')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                }
                }
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        lang: state.appReducer.lang,
    };
}
function mapDispatchToProps(dispatch) {
    return {
        exportXLSX: (data, title) => dispatch(exportXLSX(data, title)),
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ExportXLSX);