import React from 'react';
import { connect } from 'react-redux';
import {
    withStyles, Table, Tooltip, TableBody, TableCell, TableHead,
    TableRow, IconButton, Typography
} from '@material-ui/core';
import i18n from '../i18n/i18n';
import * as Icons from '@material-ui/icons';
import { exportDocument } from '../actions/ExportActions';
import { deleteTemplate } from '../actions/AppActions';
import { Popup } from '../components/Popup';
import ExportXLSX from './ExportXLSX'

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        height: '45px',
        fontWeight: '400',
        fontSize: 17
    },
    body: {
        fontSize: 14
    },
    root: {
        padding: '5px',
        textAlign: 'left',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        verticalAlign: 'inherit'
    }
}))(TableCell);

const styles = theme => ({
    innerContainer: {
        margin: '0 5px',
        width: '100%'
    },
    container: {
        padding: '10px'
    }
});

export class TemplateTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            export: false,
            exportXLSX: false
        }
    }

    exportButtonClick = (item) => {
        this.setState({ export: !this.state.export });
        if (item && item.putanje) {
            this.setState({ checked: item.putanje.split(',') });
        }
    }

    exportXLSXButtonClick = (item) => {
        if (item && item.putanje) {
            this.setState({ checked: item.putanje.split(','), template: item.naziv });
        }
        this.setState({ exportXLSX: !this.state.exportXLSX });
    }
    exportFunction = () => {
        this.props.exportDocument(this.state.docTitle, this.state.checked);
        this.setState({ export: !this.state.export });
    }

    deleteButtonClick = (item) => {
        this.props.deleteTemplate(item.id);
    }

    render() {
        const { classes, templates } = this.props;
        const templateRender = templates.length !== 0 && templates.map((item, i) => {
            if (!item) return null;
            return <TableRow key={i}>
                <CustomTableCell>{item.naziv}</CustomTableCell>
                <CustomTableCell>{item.putanje.split(',').join(', ')}</CustomTableCell>
                <CustomTableCell>
                    <Tooltip title={i18n.t("Delete")}>
                        <IconButton aria-label="Delete"
                            color="secondary"
                            className={classes.cssRoot}
                            onClick={() => this.deleteButtonClick(item, i)}
                        >
                            <Icons.Delete />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={i18n.t("Export")}>
                        <IconButton aria-label="export"
                            color="primary"
                            onClick={() => this.exportButtonClick(item)}
                        >
                            <Icons.CloudDownload />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={i18n.t("Export XLSX")}>
                        <IconButton aria-label="export"
                            color="primary"
                            onClick={() => this.exportXLSXButtonClick(item)}
                        >
                            <Icons.CloudDownload />
                        </IconButton>
                    </Tooltip>
                </CustomTableCell>
            </TableRow>
        })

        return (
            <div className={classes.container}>
                <Typography variant="title">{i18n.t('Templates')}</Typography>
                <div className={classes.innerContainer}>
                    <Table className={classes.innerContainer}>
                        <TableHead>
                            <TableRow>
                                <CustomTableCell>{i18n.t('Title')}</CustomTableCell>
                                <CustomTableCell>{i18n.t('Paths')}</CustomTableCell>
                                <CustomTableCell>{i18n.t('Delete/Export')}</CustomTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {templateRender}
                        </TableBody>
                    </Table>
                </div>
                {this.state.export && <Popup
                    open={this.state.export}
                    onTextChange={({ title }) => {
                        this.setState({ docTitle: title });
                    }}
                    handleClose={this.exportButtonClick}
                    onOk={this.exportFunction}
                    dialogTitle='Export'
                    label='Document title' />
                }

                {this.state.exportXLSX && <ExportXLSX
                    open={this.state.exportXLSX}
                    handleClose={this.exportXLSXButtonClick}
                    onOk={this.exportXLSXFunction}
                    paths={this.state.checked}
                    template={this.state.template}
                    dialogTitle='Export XLSX'
                    label='Document title' />
                }
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        templates: state.appReducer.templates,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        exportDocument: (title, checked) => dispatch(exportDocument(title, checked)),
        deleteTemplate: (id) => dispatch(deleteTemplate(id)),
    }
}
export default withStyles(styles, { withTheme: true })(connect(
    mapStateToProps,
    mapDispatchToProps
)(TemplateTable))