import React from "react";
import { connect } from 'react-redux';
import { navigate } from "gatsby"
import i18n from '../i18n/i18n';
import MarkdownTree from '../components/MarkdownTree';
import TemplateTable from '../components/TemplateTable';
import TreeFormControl from '../components/TreeFormControl';
import { withStyles } from '@material-ui/core/styles';
import { TextField, Drawer, Button, Typography, Card } from '@material-ui/core';
import classNames from 'classnames';
import { exportDocument } from '../actions/ExportActions';
import { saveTemplate, getTemplate } from '../actions/AppActions';
import { Popup } from '../components/Popup';
import { FadeLoader } from 'react-spinners';

const drawerWidth = 250;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
        position: 'absolute',
        top: 'auto',
        height: 'auto'
    },
    page: {
        display: 'flex',
        flexFlow: 'row wrap'
    },
    button: {
        margin: theme.spacing.unit,
    },
    opacity: {
        background: 'rgba(0, 0, 0,0.7)',
        zIndex: 1201,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    loader: {
        position: 'absolute',
        opacity: 1,
        top: '50%',
        left: '50%',
        zIndex: 1202,
        transform: 'translate(-50%, -50%)',
        padding: '20px',
        background: 'rgba(0, 0, 0,0.4)',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center'
    },
    templateText: {
        maxHeight: '300px',
        overflow: 'auto',
    },
    text: {
        color: '#CCCCCC',
        fontSize: '23px'
    },
    buttonDiv: {
        display: 'flex',
        flexDirection: 'row'
    }
});
class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loggedIn: this.props.loggedIn,
            lang: 'en',
            checked: [],
            adminDrawerOpen: true,
            export: false,
            template: false,
            formControl: {
                manuals: true,
                cover: true,
                introduction: true,
                description: true,
                benefits: true,
                capabilities: false,
                grouping: true,
                coverGroup: 'ng'
            }
        }
    }

    componentDidMount() {
        let user = JSON.parse(localStorage.getItem('user'));
        this.setState({ user });
        this.props.getTemplate();
        this.setState({ lang: this.props.lang });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.loggedIn !== this.state.loggedIn) {
            let user = JSON.parse(localStorage.getItem('user'));
            this.setState({ user });
        }
        if (prevState.lang !== this.props.lang) {
            this.setState({
                lang: this.props.lang
            });
        }
    }

    setChecked = (checked) => {
        this.setState({ checked });
    }
    exportButtonClick = () => {
        this.setState({ export: !this.state.export });
    }
    exportFunction = () => {
        this.props.exportDocument(this.state.docTitle, this.state.checked);
        this.setState({ export: !this.state.export });
    }
    saveButtonClick = () => {
        this.setState({ template: !this.state.template });
    }
    saveTemplateFunction = () => {
        this.props.saveTemplate(this.state.templateTitle, this.state.checked.join());
        this.setState({ template: !this.state.template });
    }

    handleChangeFormControl = (checkbox, e) => {
        var formControl = { ...this.state.formControl };
        if (checkbox === 'coverGroup') {
            formControl[checkbox] = e.target.value;
        } else formControl[checkbox] = !formControl[checkbox];
        this.setState({ formControl });
    }

    render() {
        const { user, adminDrawerOpen, formControl, lang } = this.state;
        const { classes, exporting } = this.props;
        if (user === null || (user && user.id_klijent_grupa !== 12)) {
            navigate('/')
        }
        return (
            <div position="relative">
                <TreeFormControl
                    changeFormControl={this.handleChangeFormControl}
                    checkedBoxes={formControl}
                    lang={lang}
                />
                <Drawer
                    className={classes.drawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    variant='persistent'
                    open={adminDrawerOpen}
                    anchor="left"
                >
                    <MarkdownTree checked={this.setChecked} formControl={formControl} />
                </Drawer>

                <Card className={classNames(classes.appBar)}>
                    {this.state.checked && this.state.checked.length !== 0 &&
                        <div>
                            <div className={classes.buttonDiv}>
                                <Button
                                    variant="contained"
                                    primary='true'
                                    color='primary'
                                    className={classes.button}
                                    onClick={() => this.exportButtonClick()}>
                                    Export
                            </Button>
                                <Button
                                    variant="contained"
                                    primary='true'
                                    color='primary'
                                    className={classes.button}
                                    onClick={() => this.saveButtonClick()}>
                                    Save template
                            </Button>
                            </div>
                            <TextField
                                disabled
                                label="Checked paths"
                                placeholder="Placeholder"
                                fullWidth
                                multiline
                                inputProps={{ className: classes.templateText }}
                                value={this.state.checked.join(',\n')}
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </div>
                    }
                    <TemplateTable />
                </Card>

                {exporting && <div className={classes.opacity}>
                    <div className={classes.loader}>
                        <FadeLoader
                            color={'#4da7df'}
                            loading={exporting}
                        />
                        <Typography className={classes.text}>{i18n.t('Downloading...')}</Typography>
                    </div>
                </div>}
                {this.state.export && <Popup
                    open={this.state.export}
                    onTextChange={({ title }) => {
                        this.setState({ docTitle: title });
                    }}
                    handleClose={this.exportButtonClick}
                    onOk={this.exportFunction}
                    dialogTitle='Export'
                    label='Document title' />
                }
                {this.state.template && <Popup
                    open={this.state.template}
                    onTextChange={({ title }) => {
                        this.setState({ templateTitle: title });
                    }}
                    handleClose={this.saveButtonClick}
                    onOk={this.saveTemplateFunction}
                    dialogTitle='Save template'
                    label='Template title' />
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        lang: state.appReducer.lang,
        loggedIn: state.appReducer.loggedIn,
        exporting: state.exportReducer.exporting,
        exportFailed: state.exportReducer.exportFailed
    };
}
function mapDispatchToProps(dispatch) {
    return {
        exportDocument: (title, checked) => dispatch(exportDocument(title, checked)),
        saveTemplate: (title, checked) => dispatch(saveTemplate(title, checked)),
        getTemplate: () => dispatch(getTemplate())
    };
}
export default withStyles(styles)(connect(
    mapStateToProps,
    mapDispatchToProps
)(Admin));