import _ from 'lodash';

export const prepare = (data, paths) => {
    // console.log('dddddddddddd', data, paths)
    let group = _.chain(data.allMarkdownRemark.edges).filter(o => {
        return paths.some((item) => item == o.node.frontmatter.path)
    })
        .sortBy(o => parseInt(o.node.frontmatter.priority))
        .map(item => {
            if (!item.node.frontmatter.type.includes('capabilities') && !item.node.frontmatter.type.includes('introduction')) {
                delete item.node.rawMarkdownBody
            }
            return item;
        })
        .groupBy('node.frontmatter.module').value();
    // console.log('cccccccccccc', group);
    return group;
};