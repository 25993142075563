import React from 'react';
import { connect } from 'react-redux';
import { StaticQuery, graphql } from 'gatsby';
import _ from 'lodash';
import MTree from './Tree';
import { structuredTree } from '../utils/treeStructuring'

class MarkdownTree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: 'en'
        }
    }
    componentDidMount() {
        this.setState({ lang: this.props.lang });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.lang !== this.props.lang) {
            this.setState({
                lang: this.props.lang
            });
        }
    }

    render() {
        const { lang } = this.state;
        const { formControl } = this.props;


        return (
            <StaticQuery
                query={graphql`
                    query HeadingQuery {
                        allMarkdownRemark(sort: {order: ASC, fields: [frontmatter___module, frontmatter___priority]}) {
                          totalCount
                            edges {
                              node {
                                frontmatter {
                                  title
                                  path
                                  lang
                                  priority
                                  formPriority
                                  type
                                  module
                                }
                                id
                              }
                          }
                        }
                      }`
                }
                render={data => {

                    let treeData = structuredTree(data, lang, formControl);

                    return (
                        <div>
                            <MTree nodes={treeData} setChecked={this.props.checked} lang={this.state.lang} />
                        </div>
                    )
                }
                }
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        lang: state.appReducer.lang,
    };
}
function mapDispatchToProps(dispatch) {
    return {
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MarkdownTree);